import React from "react";

const SearchIcon = ({
  viewBox = "0 0 24 24 ",
  size = 24,
  color = "#9fa4a6",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="29698"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M200.52992 357.98016c2.7136 1.64864 5.67296 2.36544 8.64256 2.36544 5.77536 0 11.42784-2.93888 14.60224-8.26368 48.20992-81.17248 136.704-131.584 230.9632-131.584a16.9472 16.9472 0 1 0 0-33.90464c-106.17856 0-205.83424 56.76032-260.10624 148.1728a16.92672 16.92672 0 0 0 5.89824 23.21408z m-19.83488 49.27488a16.97792 16.97792 0 0 0-20.39808 12.63616c-5.0688 21.62688-7.90528 35.04128-7.90528 59.12576a16.9472 16.9472 0 1 0 33.92512 0c0-20.4288 2.28352-31.08864 7.04512-51.39456a16.97792 16.97792 0 0 0-12.66688-20.36736z m793.99936 485.29408l-172.60544-172.6464c-0.4608-0.48128-0.94208-0.90112-1.42336-1.34144a412.78464 412.78464 0 0 0 69.45792-229.59104c0-229.07904-186.3168-415.37536-415.3856-415.37536C225.71008 73.58464 39.36256 259.8912 39.36256 488.96c0 229.00736 186.34752 415.3856 415.37536 415.3856a412.95872 412.95872 0 0 0 226.03776-67.15392l-49.37728-49.41824c-51.85536 30.76096-112.128 48.73216-176.66048 48.73216-191.66208 0-347.56608-155.904-347.56608-347.53536 0-191.66208 155.904-347.56608 347.56608-347.56608 191.65184 0 347.56608 155.904 347.56608 347.56608 0 90.30656-34.90816 172.41088-91.5968 234.2912l38.68672 38.69696c1.35168 2.0992 2.90816 4.07552 4.75136 5.92896l172.61568 172.58496a33.7408 33.7408 0 0 0 23.9616 9.94304c8.68352 0 17.38752-3.29728 23.99232-9.94304a33.85344 33.85344 0 0 0-0.02048-47.9232z"
      fill={color}
      p-id="29699"
    ></path>
  </svg>
);

export default SearchIcon;
