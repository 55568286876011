import React from "react";
const SeeIcon = ({
  viewBox = "0 0 24 24",
  size = 24,
  color = "rgb(65, 71, 85)",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="8783"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M512 123.6385189c205.34613333 0 390.01201778 141.84903111 481.9565989 370.25412665 4.66033778 11.60229888 4.66033778 24.61240889 0 36.2147089C902.01201778 758.51244999 717.34613333 900.3614811 512 900.3614811c-205.29758777 0-390.01201778-141.84903111-481.9565989-370.25412665a48.64227555 48.64227555 0 0 1 0-36.2147089C121.98798222 265.48755001 306.75095666 123.6385189 512 123.6385189z m0 97.09036999c-159.61656889 0-305.34921443 111.07138333-384.28368555 291.27111111C206.65078557 692.19972779 352.38343111 803.27111111 512 803.27111111c159.61656889 0 305.39776-111.07138333 384.28368555-291.27111111C817.39776 331.80027221 671.61656889 220.72888889 512 220.72888889z m0 97.09036999c107.0906789 0 194.18074112 87.09006222 194.18074112 194.18074112s-87.09006222 194.18074112-194.18074112 194.18074112-194.18074112-87.09006222-194.18074112-194.18074112 87.09006222-194.18074112 194.18074112-194.18074112z m0 97.09037113c-53.54533888 0-97.09036999 43.54503111-97.09036999 97.09036999s43.54503111 97.09036999 97.09036999 97.09036999 97.09036999-43.54503111 97.09036999-97.09036999-43.54503111-97.09036999-97.09036999-97.09036999z"
      fill={color}
      p-id="8784"
    ></path>
  </svg>
);


export default SeeIcon;