import React, { Component } from "react";
import "../styles/entrance.scss";
import SearchIcon from "../icons/SearchIcon";
import LogOutIcon from "../icons/LogOutIcon";
import TabBar from "./common/TabBar";
import Home from "./Home";
import Login from "./Login";
import HamburgerIcon from "../icons/HamburgerIcon";
import AllowRight from "../icons/AllowRight";
import cookie from "react-cookies";
import File from "./File";

import {
  BrowserRouter as Router,
  Switch,
  HashRouter,
  Route,
  Redirect,
} from "react-router-dom";

class Entrance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reduce: false,
      username: "",
    };
    this.routeLogin = this.routeLogin.bind(this);
    this.clickReduce = this.clickReduce.bind(this);
  }

  componentDidMount() {
    let param = cookie.load("token");
    if (param) {
      this.setState({ username: param.userName });
    } else {
      const { history } = this.props;
      history.push("/Login");
    }
  }

  routeLogin() {
    const { history } = this.props;
    console.log('----------remove ==========');
    cookie.remove("token");
    history.push("/Login");
  }

  clickReduce() {
    this.setState({ reduce: !this.state.reduce });
  }
  render() {
    const { reduce, username } = this.state;
    return (
      <React.Fragment>
        <div className="body-container">
          {/* 左 */}
          <div className={`left-bar ${reduce ? "reduce-left" : ""}`}>
            <TabBar {...this.props} />
          </div>
          {/* 右 */}
          <div className={`right-bar ${reduce ? "reduce-right" : ''}`}>
            <header>
              <div className="head-bar">
                <div className="nav-header">
                  <div className="header-left">
                    {reduce ? (
                      <AllowRight
                        size={40}
                        color="#bb646e"
                        onClick={this.clickReduce}
                      />
                    ) : (
                      <HamburgerIcon
                        size={40}
                        color="#bb646e"
                        onClick={this.clickReduce}
                      />
                    )}
                  </div>
                  <div className="header-right">
                    <div className="profile">
                      <div className="left">
                        <div className="user-text">
                          <p>Hi,</p>
                          <h6>{username}</h6>
                        </div>
                      </div>
                      {/* logout */}
                      <div className="logout" onClick={this.routeLogin}>
                        <LogOutIcon
                          size={28}
                          color="#df5555"
                          className="logout-icon"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </header>
            <div className="page-contain">
              <Switch>
                <Route path="/Login" component={Login}></Route>
                <Route path="/Home" component={Home}></Route>
                <Route path="/File" component={File}></Route>
                <Redirect to="/Home" />
              </Switch>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Entrance;
