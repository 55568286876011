import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const deleteFile = (id) => {
  return api.post(
    `${ApiEndpointConstants.DELETE_FILE}`,
    {},
    { params: { id } }
  );
};
