import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const getFileList = (page, per_page, name, status) => {
  return api.post(
    `${ApiEndpointConstants.GET_FILES}`,
    { page, per_page, name, status: `${status}` }
  
  );
};
