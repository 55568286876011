import React from "react";

const UsersIcon = ({
    viewBox="0 0 24 24",
    size = 24,
    color = "#9fa4a6",
    ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="13290"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M808 296C808 132.79 675.21 0 512 0S216 132.79 216 296c0 101.094 50.96 190.5 128.524 243.93-72.422 24.984-138.798 66.266-194.564 122.03C53.26 758.664 0 887.242 0 1024h80c0-238.204 193.796-432 432-432 163.21 0 296-132.79 296-296zM512 512c-119.1 0-216-96.9-216-216S392.9 80 512 80s216 96.9 216 216-96.9 216-216 216z m132 271.96h380v80H644zM1024 624v80H644v-80zM644 944h380v80H644z m0 0"
      p-id="13291"
      fill={color}
    ></path>
  </svg>
);

export default UsersIcon;