import React, { Component } from "react";
import "../../styles/common/tabBar.scss";
import EmailLogo from "../../icons/EmailLogo";
import UsersIcon from "../../icons/UsersIcon";
import RightAllowIcon from "../../icons/RightAllowIcon";
import EmailManagement from "../../icons/EmailManagement";
import FileIcon from "../../icons/FileIcon";
import ChartIcon from "../../icons/ChartIcon";

class TabBar extends Component {
  constructor(props) {
    super(props);
    this.routeHome = this.routeHome.bind(this);
    this.routeFile = this.routeFile.bind(this);
    this.routeEmail = this.routeEmail.bind(this);
  }

  routeHome() {
    const { history } = this.props;
    history.push("/Home");
  }
  routeFile() {
    const { history } = this.props;
    history.push("/File");
  }
  routeEmail() {
    const { history } = this.props;
    history.push("/Email");
  }

  render() {
    let { location } = this.props;
    return (
      <React.Fragment>
        <div className="tab-box">
          <div className="tab">
            <div className="header">
              <EmailLogo size={50} color="#9a444e" />
              <h1>Mail System</h1>
            </div>
            <ul className="tab-list">
              {location.pathname === "/Home" ? (
                <li
                  className={location.pathname === "/Home" ? "active" : ""}
                  onClick={this.routeHome}
                >
                  <ChartIcon size={24} color="#fff" />
                  <p>Home</p>
                  <RightAllowIcon size={24} color="#fff" className="right" />
                </li>
              ) : (
                <li onClick={this.routeHome}>
                  <ChartIcon size={24} />
                  <p>Home</p>
                  <RightAllowIcon size={24} className="right" />
                </li>
              )}
              {location.pathname === "/File" ||
              location.pathname === "/EditFile" ? (
                <li
                  className={
                    location.pathname === "/File" ||
                    location.pathname === "/EditFile"
                      ? "active"
                      : ""
                  }
                  onClick={this.routeFile}
                >
                  <UsersIcon size={24} color="#fff" />
                  <p>Files</p>
                  <RightAllowIcon size={24} color="#fff" className="right" />
                </li>
              ) : (
                <li onClick={this.routeFile}>
                  <UsersIcon size={24} />
                  <p>Files</p>
                  <RightAllowIcon size={24} className="right" />
                </li>
              )}
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TabBar;
