import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const getCode = (username) => {
  return api.post(
    `${ApiEndpointConstants.GET_CODE}`,
    {},
    {
      params: {
        UserName: username,
      },
    }
  );
};
