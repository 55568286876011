import React, { Component } from "react";
import "../styles/login.scss";
import * as LoginAPI from "../apis/LoginAPI";
import cookie from "react-cookies";
import Loading from "../components/common/Loading";
import { setAPIAuthorizationToken } from "../apis/API";
import * as GetCodeAPI from "../apis/GetCodeAPI";

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      username: "",
      password: "",
      code: "",
    };
    this.changeInput = this.changeInput.bind(this);
    this.Login = this.Login.bind(this);
    this.getCode = this.getCode.bind(this);
  }

  changeInput(e, type) {
    switch (type) {
      case "username":
        this.setState({ username: e.target.value });
        break;
      case "password":
        this.setState({ password: e.target.value });
        break;
      case "code":
        this.setState({ code: e.target.value });
        break;
      default:
        break;
    }
  }

  getCode() {
    const { username } = this.state;
    this.setState({ loadingShow: true });
    GetCodeAPI.getCode(username).then((res) => {
      this.setState({ loadingShow: false });
      var code = res.data.code;
      if (code === 200) {
        var data = res.data.data;
        console.log(data);
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: "已发送",
          })
        );
      }
    });
  }

  Login() {
    const { username, password, code } = this.state;
    console.log(username, password);
    LoginAPI.Login(username, password, code).then((res) => {
      console.log(res);
      var code = res.data.code;
      if (code === 200) {
        var data = res.data;
        if (data) {
          console.log(data);
          let expiration = data.time * 1000;
          let time = new Date(new Date(expiration).getTime());
          console.log(time);
          let param = data;
          console.log(data.data);
          setAPIAuthorizationToken(data.data);
          cookie.save("token", JSON.stringify(param), {
            expires: time,
            path: "/",
          });
          const { history } = this.props;
          history.push("/");
          window.dispatchEvent(
            new CustomEvent("showAlert", {
              detail: data.msg,
            })
          );
        }
      } else {
        var msg = res.data.msg;
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      }
    });
  }
  render() {
    return (
      <React.Fragment>
        <Loading loadingShow={this.state.loadingShow} />
        <div className="login-container">
          <div className="login-box">
            <div className="login-left">
              <span className="circle">
                <span></span>
                <span></span>
              </span>
              <span className="star">
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
                <span></span>
              </span>
              <span className="fly_star">
                <span></span>
                <span></span>
              </span>
              <p className="title">
                <i>M</i>
                <i>A</i>
                <i>I</i>
                <i>L</i>
              </p>
            </div>
            <div className="login-right">
              <div className="title">LOG IN</div>
              <div className="row">
                <input
                  type="text"
                  placeholder="username"
                  onChange={(e) => {
                    this.changeInput(e, "username");
                  }}
                />
              </div>
              <div className="row">
                <input
                  type="text"
                  placeholder="password"
                  onChange={(e) => {
                    this.changeInput(e, "password");
                  }}
                />
              </div>
              <div className="code-row">
                <input
                  type="text"
                  placeholder="code"
                  onChange={(e) => this.changeInput(e, "code")}
                />
                <div className="btn" onClick={this.getCode}>
                  Get Code
                </div>
              </div>
              <div className="btn-row">
                <div className="btn" onClick={this.Login}>
                  LOG IN
                </div>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Login;
