import React, { Component } from "react";
import "../styles/file.scss";
import EditIcon from "../icons/EditIcon";
import Pagination from "@material-ui/lab/Pagination";
import AddEmail from "../icons/AddEmail";
import Delete from "../icons/DeleteIcon";
import NoContent from "../icons/NoContent";
import SearchIcon from "../icons/SearchIcon";
import cookie from "react-cookies";
import Select from "react-select";
import { setAPIAuthorizationToken } from "../apis/API";
import * as GetFileListAPI from "../apis/GetFIleListAPI";
import * as DeleteFileAPI from "../apis/DeleteFileAPI";
import Loading from "./common/Loading";
import SubmitIcon from "../icons/SubmitIcon";
import CloseIcon from "../icons/CloseIcon";
import * as SendEmailAPI from "../apis/SendEmailAPI";
import SeeIcon from "../icons/SeeIcon";
import NoSeeIcon from "../icons/NoSeeIcon";
import * as ChangeFileContentAPI from "../apis/ChangeFileContentAPI";
import Reload from "../icons/Reload";

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      per_page: 6,
      loadingShow: false,
      count: 8,
      list: [],
      status: "",
      statusArr: [
        {
          value: 0,
          label: "OFF",
        },
        {
          value: 1,
          label: "ON",
        },
        {
          value: "",
          label: "ALL",
        },
      ],
      stateValue: [],
      status: "",
      searchValue: "",
      name: "",
      emailFileArr: [],
      emailSelect: {},
      emailMask: false,
      toEmailUser: "",
      checkFile: true,
      locating: "",
      replace: "",
      content: "",
      subjectLocating: "",
      subjectReplace: "",
    };
    this.handlePageChange = this.handlePageChange.bind(this);
    this.routeEditFile = this.routeEditFile.bind(this);
    this.inputChange = this.inputChange.bind(this);
    this.searchBtn = this.searchBtn.bind(this);
    this.changeEmailMask = this.changeEmailMask.bind(this);
    this.sendEmailButton = this.sendEmailButton.bind(this);
    this.changeCheckFile = this.changeCheckFile.bind(this);
    this.reload = this.reload.bind(this);
  }

  handlePageChange(e, page) {
    this.setState({ page: page }, () => {
      this.getFileList();
    });
  }

  componentDidMount() {
    this.getFileList();
  }
  getFileList() {
    let param = cookie.load("token");
    console.log(param);
    if (param) {
      const { page, per_page, name } = this.state;
      setAPIAuthorizationToken(param.data);
      this.setState({ loadingShow: true });
      GetFileListAPI.getFileList(page, per_page, name, "").then((res) => {
        console.log(res);
        this.setState({ loadingShow: false });
        var code = res.data.code;
        if (code === 200) {
          var data = res.data.data;
          var total = res.data.total;
          let count = Math.ceil(total / per_page);
          this.setState({ count });
          this.setState({ list: data });
          // data
          let emailFileArr = [];
          data.map((item, index) => {
            console.log(item);
            item = {
              label: item.filename,
              value: item.id,
            };
            emailFileArr.push(item);
          });
          console.log(emailFileArr);
          this.setState({ emailFileArr });
        }
      });
    }
  }

  routeEditFile(id) {
    const { history } = this.props;
    if (id !== "") {
      history.push(`/EditFile?id=${id}`);
    } else {
      history.push(`/EditFile`);
    }
  }

  inputChange(e, type) {
    switch (type) {
      case "select":
        this.setState({ stateValue: e });
        this.setState({ status: e.value }, () => {
          this.getFileList();
        });
        break;
      case "search":
        this.setState({ name: e.target.value });
        break;
      case "emailFile":
        this.setState({ emailSelect: e }, () => {
          const { locating, replace, subjectLocating, subjectReplace } =
            this.state;
          this.setState({ loadingShow: true });
          ChangeFileContentAPI.changeFile(
            e.value,
            locating,
            replace,
            subjectLocating,
            subjectReplace
          ).then((res) => {
            console.log(res);
            this.setState({ loadingShow: false });
            var code = res.data.code;
            if (code === 200) {
              var data = res.data.data;
              if (data.locating === null || data.subjectlocating === null) {
                this.setState({ content: data.content });
                return;
              }
              this.setState({
                content: data.content,
                locating: data.locating,
                subjectLocating: data.subjectlocating,
              });
            }
          });
        });
        break;
      case "email":
        this.setState({ toEmailUser: e.target.value });
        break;
      case "locating":
        this.setState({ locating: e.target.value });
        break;
      case "replace":
        this.setState({ replace: e.target.value });
        break;
      case "subjectLocating":
        this.setState({ subjectLocating: e.target.value });
        break;
      case "subjectReplace":
        this.setState({ subjectReplace: e.target.value });
        break;

      default:
        break;
    }
  }
  searchBtn() {
    this.getFileList();
  }

  sendEmailButton() {
    const {
      toEmailUser,
      emailSelect,
      locating,
      replace,
      subjectLocating,
      subjectReplace,
    } = this.state;
    this.setState({ loadingShow: true });
    SendEmailAPI.sendEmail(
      emailSelect.value,
      toEmailUser,
      locating,
      replace,
      subjectLocating,
      subjectReplace
    ).then((res) => {
      this.setState({ loadingShow: false });
      var code = res.data.code;
      var msg = res.data.msg;
      if (code === 200) {
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
        this.setState({ emailMask: !this.state.emailMask, replace: "" });
      } else {
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: msg,
          })
        );
      }
    });
  }

  delete(id) {
    this.setState({ loadingShow: true });
    DeleteFileAPI.deleteFile(id).then((res) => {
      console.log(res);
      this.setState({ loadingShow: false });
      var code = res.data.code;
      if (code === 200) {
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: "删除成功",
          })
        );
        this.getFileList();
      }
    });
  }

  changeEmailMask(id) {
    const { emailFileArr } = this.state;
    emailFileArr.map((item) => {
      if (item.value === id) {
        this.setState({ emailSelect: item });
      }
    });
    this.setState({ emailMask: !this.state.emailMask });
    const { locating, replace, subjectLocating, subjectReplace } = this.state;
    if (id) {
      this.setState({ loadingShow: true });
      ChangeFileContentAPI.changeFile(
        id,
        locating,
        replace,
        subjectLocating,
        subjectReplace
      ).then((res) => {
        this.setState({ loadingShow: false });
        console.log(res);
        var code = res.data.code;
        if (code === 200) {
          var data = res.data.data;
          if (data.locating === null || data.subjectlocating === null) {
            this.setState({ content: data.content });
            return;
          }
          this.setState({
            content: data.content,
            locating: data.locating,
            subjectLocating: data.subjectlocating,
          });
        }
      });
    }
  }

  reload() {
    const { emailSelect, locating, replace, subjectLocating, subjectReplace } =
      this.state;
    this.setState({ loadingShow: true });
    ChangeFileContentAPI.changeFile(
      emailSelect.value,
      locating,
      replace,
      subjectLocating,
      subjectReplace
    ).then((res) => {
      this.setState({ loadingShow: false });
      console.log(res);
      var code = res.data.code;
      if (code === 200) {
        var data = res.data.data;
        this.setState({ content: data.content, locating: data.locating });
      } else {
        window.dispatchEvent(
          new CustomEvent("showAlert", {
            detail: "预览失败",
          })
        );
      }
    });
  }

  changeCheckFile() {
    this.setState({ checkFile: !this.state.checkFile });
  }

  render() {
    const {
      page,
      count,
      list,
      statusArr,
      emailFileArr,
      emailSelect,
      emailMask,
      content,
      checkFile,
      locating,
      replace,
      subjectLocating,
      subjectReplace,
    } = this.state;
    return (
      <React.Fragment>
        {/* mask */}
        <div className={`email-mask ${emailMask ? "show-email" : ""}`}>
          <div className="mask">
            <div className="email-box">
              <div className="head">
                Send Mail
                <CloseIcon
                  size={20}
                  color="#fff"
                  onClick={() => this.changeEmailMask("")}
                  className="icon"
                />
              </div>
              <div className="content">
                <div className="row">
                  <input
                    type="text"
                    placeholder="Email ..."
                    onChange={(e) => this.inputChange(e, "email")}
                  />
                </div>
                <div className="row">
                  <Select
                    className="control"
                    value={emailSelect}
                    options={emailFileArr}
                    onChange={(e) => this.inputChange(e, "emailFile")}
                  />
                </div>
                <div className="row">
                  <span>Subject Key :</span>{" "}
                  <input
                    type="text"
                    value={subjectLocating || ""}
                    placeholder="Subject key ..."
                    onChange={(e) => this.inputChange(e, "subjectLocating")}
                  />
                </div>
                <div className="row">
                  <span>Subject Replace:</span>{" "}
                  <input
                    type="text"
                    value={subjectReplace || ""}
                    placeholder="Subject Replace ..."
                    onChange={(e) => this.inputChange(e, "subjectReplace")}
                  />
                </div>
                <div className="row">
                  <span>Replace Key:</span>{" "}
                  <input
                    type="text"
                    value={locating || ""}
                    placeholder="key ..."
                    onChange={(e) => this.inputChange(e, "locating")}
                  />
                </div>
                <div className="row">
                  <span>Replace Value :</span>{" "}
                  <input
                    type="text"
                    value={replace || ""}
                    placeholder="value ..."
                    onChange={(e) => this.inputChange(e, "replace")}
                  />
                </div>
                <div className="icon-row">
                  {checkFile ? (
                    <SeeIcon
                      size={30}
                      color="#bb646e"
                      className="icon"
                      onClick={this.changeCheckFile}
                    />
                  ) : (
                    <NoSeeIcon
                      size={30}
                      color="#bb646e"
                      className="icon"
                      onClick={this.changeCheckFile}
                    />
                  )}
                  <div className="reload">
                    <Reload
                      size={30}
                      color="#bb646e"
                      className="icon"
                      onClick={this.reload}
                    />
                  </div>
                </div>
                <div
                  className={`email-content-row ${
                    checkFile ? "show-file" : ""
                  }`}
                >
                  <p dangerouslySetInnerHTML={{ __html: content }}></p>
                </div>
                <div className="button">
                  <SubmitIcon
                    color="#bb646e"
                    size={30}
                    onClick={this.sendEmailButton}
                    className="icon"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <Loading loadingShow={this.state.loadingShow} />
        <div className="file-contain">
          <div className="card">
            <div className="header">
              <div className="left">
                <div className="state-box" onClick={this.changeState}>
                  <Select
                    className="control"
                    // value={stateValue}
                    options={statusArr}
                    onChange={(e) => this.inputChange(e, "select")}
                  />
                </div>
                {/* search */}
                <div className="search-box">
                  <input
                    type="text"
                    placeholder="Search here ... "
                    className="search"
                    onChange={(e) => this.inputChange(e, "search")}
                  />
                  <SearchIcon
                    size={30}
                    className="search-icon"
                    color="#fff"
                    onClick={this.searchBtn}
                  />
                </div>
              </div>
            </div>
            <div className="contain">
              <ul className="header-list">
                <div className="left">
                  <li className="email">Name</li>
                  <li className="status">Status</li>
                  <li className="time">Time</li>
                </div>
                <div className="right">
                  <li className="operation">Operation</li>
                </div>
              </ul>
              {list.length > 0 ? (
                <div className="container">
                  {list.map((item, index) => {
                    return (
                      <ul className="list" key={index}>
                        <div className="left">
                          <li className="email">{item.filename}</li>
                          <li className="status">
                            {" "}
                            <p className={item.status === "1" ? "on" : "off"}>
                              {item.status === "1" ? "ON" : "OFF"}
                            </p>
                          </li>
                          <li className="time">{item.updateTime}</li>
                        </div>
                        <div className="right">
                          <li className="operation">
                            {/*
                            <Delete
                              size={24}
                              color="#bb646e"
                              onClick={() => this.delete(item.id)}
                              className="icon"
                            />*/}
                            <SubmitIcon
                              size={24}
                              color="#bb646e"
                              className="icon"
                              onClick={() => this.changeEmailMask(item.id)}
                            />
                          </li>
                        </div>
                      </ul>
                    );
                  })}
                </div>
              ) : (
                <div className="none-container">
                  <NoContent size={200} />
                </div>
              )}
            </div>
          </div>

          <div className="pagination">
            {list.length > 0 ? (
              <Pagination
                count={count}
                page={page}
                onChange={this.handlePageChange}
                variant="outlined"
                color="standard"
              />
            ) : (
              ""
            )}
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Home;
