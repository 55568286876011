import React, { Component } from "react";

import "../../styles/common/loading.scss";
import load from "../../image/load.gif";

class Loading extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { loadingShow } = this.props;
    return (
      <React.Fragment>
        <div className={`mask ${loadingShow ? "loading-show" : ""}`}>
          <div className="load">
            <div className="img">
              <img src={load} alt="" />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Loading;
