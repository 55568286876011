import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "../apis/API";

export const getChart = (userName, starttime, endtime) => {
  return api.post(`${ApiEndpointConstants.GET_CHART}`, {
    userName,
    starttime,
    endtime,
  });
};
