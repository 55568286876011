export const BASE_URL =
  window.location.host.indexOf("localhost") < 0
    ? window.location.protocol + "//" + window.location.host
    : "https://www.hsmail.vip";
// export const BASE_URL = 'https://test.stripevip.com';
export default {
  LOGIN: "/Login/Login",
  GET_FILES: "/EmailTemplate/GetTemplateList",
  ADD_FILE: "/EmailTemplate/AddTemplate",
  GET_FILE: "/EmailTemplate/GetTemplate",
  DELETE_FILE: "/EmailTemplate/DeleteTemplate",
  EDIT_FILE: "/EmailTemplate/UpdateTemplate",
  SEND_EMAIL: "/api/Email/SingleEmailSend",
  CHANGE_File: "/EmailTemplate/PreviewTemplate",
  GET_CODE: "/Login/Telms",
  GET_CHART: "/Instrumentpanel/NumberOfMessagesSent",
};
