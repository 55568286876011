import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const sendEmail = (
  emailTemplateId,
  toEmailUser,
  locating,
  replace,
  subjectLocating,
  subjectReplace
) => {
  return api.post(`${ApiEndpointConstants.SEND_EMAIL}`, {
    emailTemplateId,
    toEmailUser,
    locating,
    replace,
    subjectlocating: subjectLocating,
    subjectreplace: subjectReplace,
  });
};
