import ApiEndpointConstants from "../constants/ApiEndpointConstants";
import api from "./API";

export const changeFile = (
  id,
  locating,
  replace,
  subjectLocating,
  subjectReplace
) => {
  return api.post(`${ApiEndpointConstants.CHANGE_File}`, {
    id,
    locating,
    replace,
    subjectlocating: subjectLocating,
    subjectreplace: subjectReplace,
  });
};
