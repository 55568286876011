import React from "react";

const HamburgerIcon = ({
  size = 24,
  color = "#404853",
  viewBox = "0 0 24 24",
  ...props
}) => (
  <svg
    viewBox="0 0 1024 1024"
    p-id="50642"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M0 0h1024v1024H0z"
      fill={color}
      fillOpacity="0"
      p-id="50643"
    ></path>
    <path
      d="M170.667 213.333H768q42.667 0 42.667 42.667T768 298.667H170.667Q128 298.667 128 256t42.667-42.667zM170.667 469.333h682.666Q896 469.333 896 512t-42.667 42.667H170.667Q128 554.667 128 512t42.667-42.667zM170.667 725.333h384q42.666 0 42.666 42.667t-42.666 42.667h-384Q128 810.667 128 768t42.667-42.667z"
      fill={color}
      p-id="50644"
    ></path>
  </svg>
);

export default HamburgerIcon;
