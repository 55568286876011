import React from "react";

const EmailLogo = ({
    color="#708B97",
    viewBox= "0 0 24 24",
    size = 24,
    ...props
}) => (
  <svg
    viewBox="0 0 1651 1024"
    p-id="5630"
    width={size}
    height={size}
    {...props}
  >
    <path
      d="M52.488258 32.074323L828.184774 754.456774 1596.548129 32.074323H52.52129zM0 137.216l429.320258 394.074839L0 917.900387V137.216z m1651.612903-5.780645v786.465032L1210.566194 530.167742 1651.612903 131.468387zM1137.498839 595.769806l-311.097807 289.891097-315.788387-289.891097L54.040774 1024H1598.76129L1137.498839 595.769806z"
      fill={color}
      p-id="5631"
    ></path>
  </svg>
);

export default EmailLogo;